import React from "react";
//import { Link } from "react-router-dom";
//import { useQuery } from '@apollo/client';

const Scheduler = () => {
  return (
    <>
      <h1 className="text-info text-3xl my-3 text-center">Scheduler</h1>
      <h1 className="text-info text-xl my-3 text-center">
        Online Scheduler Tool Coming Soon!
      </h1>
    </>
  );
};

export default Scheduler;
