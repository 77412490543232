import React from "react";
import { useQuery, useMutation } from "@apollo/client";
export default function AdminSettings() {
  // if (loading) {
  //   return <h2>...loading</h2>;
  // }
  // if (error) {
  //   return <h2>Error: {error.message}</h2>;
  // }

  // if (!loading) {

  return (
    <>
      <h2>Admin Console</h2>
    </>
  );
}
// }
