import React from "react";
//import { Link } from "react-router-dom";
//import { useQuery } from '@apollo/client';

const Reviews = () => {
  return (
    <>
      <h1 className="text-info text-3xl my-3 text-center">
        Transaction History
      </h1>
    </>
  );
};

export default Reviews;
